<template>
  <vue-office-pdf :src="pdf" @rendered="rendered" />
</template>

<script>
//引入VueOfficePdf组件
import VueOfficePdf from '@vue-office/pdf';
import policyPdf from '../../../public/pdf/policy.pdf';

export default {
  components: {
    VueOfficePdf,
  },
  data() {
    return {
      pdf: policyPdf, //设置文档地址
    };
  },
  methods: {
    rendered() {
      console.log('渲染完成');
    },
  },
};
</script>
